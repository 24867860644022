import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import './index.scss';

import Modal from '@material-ui/core/Modal';

function getModalStyle() {
    return {
        top: `100px`,
        left: `5%`,
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
});

class SimpleModalTwo extends React.Component {
    constructor(props) {
        super(props);
        this.handleOpen = this.handleOpen.bind(this);
    }

    state = {
        open: false,
    };

    handleOpen() {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        const videoURL = 'https://www.youtube.com/embed/'+this.props.videoSRC+'?modestbranding=0&amp;rel=0&amp;controls=1&amp;showinfo=0&amp;html5=1&amp;autoplay=1';
        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                    className="videoModal"
                >
                    <div style={getModalStyle()} className={classes.paper+' modalBox'}>
                        <iframe src={videoURL} title="youtube" width="100%" height="100%" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
                    </div>
                </Modal>
            </div>
        );
    }
}

SimpleModalTwo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleModalTwo);